<div
  (ghostClick)="ghostClick.emit()"
  (lockClick)="lockClick.emit()"
  [ghostConfig]="ghostConfig"
  [labels]="labels"
  [lockConfig]="lockConfig"
  [locked]="locked"
  [ngClass]="{ 'fut-card justify-content-start': isCard, 'dark-card': type === 'dark' }"
  [state]="state"
  [userLabels]="userLabels"
  appGhostOverlay
  class="overflow-hidden">
  <div class="w-100">
    @if (sectionTitle || sectionTitleRight || infoTemplate) {
      <div class="d-flex fut-card-title justify-content-between">
        @if (sectionTitle) {
          <div class="d-flex justify-content-start section-title-container">
            <h3 *futGhostStateFragment="state; text: sectionTitle | transloco: argsTitle" class="fut-h3 section-title">
              {{ sectionTitle | transloco: argsTitle }}
            </h3>
          </div>
        }
        @if (showInfoIcon && !sectionTitleRight && !infoTemplate) {
          <div class="d-flex justify-content-end">
            @if (!state || state === 'NONE') {
              <mat-icon fontSet="material-icons-outlined" class="pointer text-opacity" (click)="infoClick.next()"> info </mat-icon>
            } @else {
              <mat-icon class="fut-skeleton ghost-menu" [ngClass]="{ ghost: state === 'GHOST' }"></mat-icon>
            }
          </div>
        }
        @if (sectionTitleRight || infoTemplate) {
          <div class="d-flex justify-content-end">
            @if (sectionTitleRight) {
              <ng-container *ngTemplateOutlet="sectionTitleRight; context: { state: state, locked: locked }"></ng-container>
            }
            @if (!hideIcon) {
              <app-info-panel
                [state]="state"
                [ngClass]="{ 'ml-3': !!sectionTitleRight }"
                [infoTitle]="infoTitle || sectionTitle"
                [infoTemplate]="infoTemplate"></app-info-panel>
            }
          </div>
        }
      </div>
    }
    <ng-content></ng-content>
  </div>
</div>
